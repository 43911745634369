.search_widget__wrapper {
  position: absolute;
  bottom: calc(($nav-height / 2) * -1);
  left: 0;
  width: 100%;

  &.non-absolute {
    position: relative;
    bottom: unset;
  }

  .wrapper_container {
    padding: 0 1.5em;

    @include breakpoint(container) {
      & {
        padding: 0;
      }
    }
  }

  .search_widget__inner {
    display: flex;
    align-items: center;
    background-color: lighten($color: $light-color, $amount: 10%);
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px 0px;
    padding-left: 24px;
    padding-right: 8px;
    height: $nav-height;

    .search_widget__input {
      font-family: 'Open Sans', sans-serif;
      flex: 1 1 0%;
      outline: none;
      border: none;
      font-size: 16px;
      padding: 11px 0;
    }

    .search_widget__clear {
      display: none;

      &.active {
        display: block;
      }
    }

    .search_widget__action,
    .search_widget__clear {
      background-color: transparent;

      iconify-icon.icon {
        color: $dark-color;
      }
    }

    button {
      cursor: pointer;
    }

    .search_widget__clear:focus-visible,
    .search_widget__action:focus-visible {
      border: 2px solid $dark-color;
      border-radius: 8px;
    }
  }
}