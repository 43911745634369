restaurant-item {
  position: relative;
  background-color: white;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px 0px;
  border-radius: 9px;

  .badge {
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: bold;
  }

  .restaurant_item__header {
    height: 200px;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: center;
      border-radius: 9px;
    }

    .badge {
      position: absolute;
      top: 8px;
      left: 8px;

      background-color: #c9fde0;
      color: #00aa5b;
    }
  }
  .restaurant_item__content {
    padding: 16px;
    padding-top: 8px;

    .restaurant_title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .restaurant_item__title h3 {
        color: $dark-color;
        font-size: 1.2em;
        font-weight: 700;
        margin: 0;
        height: 44px;
        line-height: 44px;
      }
      .restaurant_item__reservasion {
        background-color: unset;

        iconify-icon {
          font-size: 24px;
          color: $dark-color;
        }
      }
    }

    .restaurant_item__rating {
      display: flex;
      gap: -4px;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
      }

      .badge {
        margin-left: 8px;
        background-color: #fff5cf;
        color: #f9a825;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      color: rgba(49, 53, 59, 0.96);
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;

      @include breakpoint(desktop) {
        & {
          font-size: 13px;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}