section.restaurant {
  text-align: center;

  &.restaurant_favorite {
    padding-bottom: 4em;
    background-color: #fffcfa;

    .wrapper_container {
      gap: 2em;
    }
  }

  .wrapper_container {
    display: flex;
    flex-direction: column;
    padding: 1.5em;

    @include breakpoint(container) {
      & {
        padding: 0;
      }
    }
  }
  .restaurant_header h2 {
    color: $dark-color;
  }
  .restaurant_contents {
    text-align: left;
    width: 100%;

    > p {
      color: rgba(49, 53, 59, 0.96);
    }

    .restaurant_lists {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1.5em;

      .skeleton-loading {
        width: 100%;
      }

      @import 'item';

      @include breakpoint(tablet) {
        & {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      @include breakpoint(desktop) {
        & {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
    .restaurant__empty_result {
      display: none;

      &.active {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        width: 100%;
        text-align: center;
        color: $dark-color;
        padding: 2em 0;

        span {
          font-size: 1.3em;

          &:first-child {
            font-size: 6em;

            @include breakpoint(tablet) {
              & {
                font-size: 10em;
              }
            }
          }
        }
      }
    }
  }
}