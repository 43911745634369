body {
  padding: 0;
  margin: 0;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

ul {
  padding-inline-start: 0;
  margin-block-start: 0;
  list-style: none;
}

input, textarea, button {
  font-family: inherit;
}