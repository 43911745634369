section.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 347px;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 5, 0.5), rgba(0, 0, 5, 0.5));
  background-size: cover;

  color: #fff;
  text-shadow: 1px 1px $dark-color;

  &.hidden {
    display: none;
  }

  .hero_image {
    width: 100%;

    img {
      display: block;
      height: 347px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      filter: brightness(0.6);
    }
  }

  .hero_inner {
    position: absolute;

    .hero_title {
      font-size: 3em;
      margin-bottom: 0.5em;
    }
    .hero_subtitle {
      padding: 0 16px;
      font-size: 1.6em;
    }

    @include breakpoint(tablet) {
      .hero_title {
        font-size: 3.5em;
      }
      .hero_subtitle {
        font-size: 1.8em;
      }
    }

    @include breakpoint(desktop) {
      .hero_title {
        font-size: 4em;
      }
      .hero_subtitle {
        font-size: 2em;
      }
    }
  }
}